<template>
    <div>
        <div class="table-responsive">
            <table class="table">
                <thead class=" text-primary">
                    <th  class="text-center">
                        
                    </th>
                    <th class="text-center">
                        Ingredient
                    </th>
                    <th class="text-center">
                        Quantity
                    </th>
                    <th class="text-center">
                        Supermarket Section
                    </th>
                </thead>
                <tbody class="shoppingList">
                    <tr v-for="ingredient in ingredientsList" :key="ingredient.id">
                        <td class="text-center">
                            <div class="custom-control custom-checkbox">
                                <div class="checkbox" style="width:auto;">
                                    <label style="font-size: 2.5em">
                                        <input type="checkbox" value="" v-model="ingredient.completed" @click="markIngredient(ingredient.id, ingredient.completed)">
                                        <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                                        
                                    </label>
                                </div>
                            </div>
                        </td>
                        <td class="text-center">
                            {{ingredient.name}}
                        </td>
                        <td class="text-center">
                            {{ingredient.quantity}} {{ingredient.measurement}}
                        </td>
                        <td class="text-center">
                            {{ingredient.supermarket_section}}
                        </td>
                        
                    </tr>

                    <tr v-for="item in extraItemsList" :key="'item'+item.id">
                        <td class="text-center">
                            <div class="custom-control custom-checkbox">
                                <div class="checkbox" style="width:auto;">
                                    <label style="font-size: 2.5em">
                                        <input type="checkbox" value="" v-model="item.completed" @click="markExtraItem(item.id, item.completed)">
                                        <span class="cr"><i class="cr-icon fa fa-check"></i></span>
                                        
                                    </label>
                                </div>
                            </div>
                        </td>
                        <td class="text-center">
                            {{item.name}}
                        </td>
                        <td class="text-center">
                            {{item.quantity}}
                        </td>
                        <td class="text-center">
                            Extra Item
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr>
        <h5 class="card-title">Additional Items</h5>
        <div class="row">
            <label class="col-md-3 col-form-label">Item Name<sup>*</sup></label>
            <div class="col-md-3">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Item Name" v-model="extra.name" required>
                </div>
            </div>
            <label class="col-md-3 col-form-label">Quantity</label>
            <div class="col-md-3">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Quantity" v-model="extra.quantity" required>
                </div>
            </div>
        </div>
         <div class="row">
             <div v-if="errors">
                <ul class="alert alert-danger">
                    <li v-for="(value, key, index) in errors" :key="index">{{ value }}</li>
                </ul>
            </div>
            <div class="col-md-12 text-center">
                <button :disabled="saving" type="submit" class="btn btn-info btn-round" @click="addItem()">Add</button>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    mounted() {
    },
    props:['ingredients', 'cart', 'extraitems'],
    data: function () {
        return {
            ingredientsList: this.ingredients,
            extraItemsList: this.extraitems,
            cartChosen: this.cart,
            errors: null,
            extra: {
                name: '',
                quantity: 1
            },
            saving: false
        }
    },
    methods: {
        markIngredient: function(ingredientId, completed) {

            let mark = {
                ingredient_id : ingredientId,
                cart_id : this.cart.id,
                mark: !completed
            };

            axios
            .post('/mark-trolley-ingredient',
                mark
            )
            .then(data => {
                
            })
            .catch(error => {
                this.errors = error.response.data.errors;
            });
        },
        markExtraItem: function(itemId, completed) {

            let mark = {
                item_id : itemId,
                cart_id : this.cart.id,
                mark: !completed
            };

            axios
            .post('/mark-extra-item',
                mark
            )
            .then(data => {
                
            })
            .catch(error => {
                this.errors = error.response.data.errors;
            });
        },
        
        addItem: function() {
            this.extra.cart_id = this.cart.id;

            axios
            .post('/extra-item',
                this.extra
            )
            .then(data => {
                console.log(data);
                this.extraItemsList.push(data.data)
                this.extra = {
                    name: '',
                    quantity: 1,
                }
            })
            .catch(error => {
                this.errors = error.response.data.errors;
            });
        }
    }
  };
</script>