<template>
    <div>
       <!-- ##### Top Category Area Start ##### -->
        <section class="top-category-area">
            <div class="container">
                <div class="row">
                    
                    <div class="col-12 col-lg-6" v-for="recipe in recommendedRecipe" :key="recipe.id">
                        <div class="single-top-category">
                            <img :src="'/storage/recommended-image/' + recipe.image" :alt="recipe.name">
                            <!-- Content -->
                            <div class="top-cta-content">
                                <h3>{{recipe.editorsChoiceName}}</h3>
                                <h6>Chosen for you</h6>
                                <a :href="'/recipe/' + recipe.slug" class="btn delicious-btn">See Full Receipe</a>
                            </div>
                        </div>
                    </div>
                    <!-- Top Category Area -->
                </div>
            </div>
        </section>
        <!-- ##### Top Category Area End ##### -->


        <!-- ##### Best Receipe Area Start ##### -->
        <section class="best-receipe-area">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="section-heading">
                            <h3>Our Recipes</h3>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <!-- Single Best Receipe Area -->
                    <div class="col-12 col-sm-6 col-lg-4" v-for="(recipe, $index) in list" :key="$index">
                        <div class="single-best-receipe-area mb-30">
                            <img loading="lazy" :src="'/storage/highest-rated-image/' + recipe.image" :alt="recipe.name">
                            <div class="receipe-content">
                                <a :href="'/recipe/' + recipe.slug">
                                    <h5>{{recipe.name}}</h5>
                                </a>
                                <div class="ratings">
                                    <i v-for="index in recipe.rating" :key="'stf-'+index" class="fas fa-star" aria-hidden="true"></i><i v-for="index in (5-recipe.rating)" :key="'ste-'+index" class="far fa-star" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <infinite-loading @distance="1" @infinite="infiniteHandler"></infinite-loading>
            </div>
        </section>
        <!-- ##### Best Receipe Area End ##### -->
          
    </div>
</template>

<script>
    import InfiniteLoading from 'vue-infinite-loading';
    const api = '/api/recipe';
    
    export default {
        mounted() {
            this.getRecommended();
        },
        components: {
            InfiniteLoading,
        },
        data: function () {
            return {
                recommendedRecipe: [],
                list: [],
                page:1
            }
        },
        methods: {
            infiniteHandler($state) {
                axios.get(api, {
                    params: {
                        page: this.page,
                    },
                }).then(({ data }) => {
                    if (data.data.length) {
                        this.page += 1;
                        this.list.push(...data.data);
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                });
            },
            getRecommended: function() {
                var self = this;
                axios.get('/api/recipe-list/recommended')
                    .then((response)=>{
                        self.recommendedRecipe = response.data;
                })
            }
        }
    };
</script>