<template>
    <div v-if="!recipe.favourited" class="book-mark-favourite-icon">
        <i class="far fa-heart text-danger" @click.stop.prevent="favouriteRecipe(true)"></i>
    </div>
        
    <div v-else class="book-mark-favourite-icon">
        <i class="fas fa-heart text-danger" @click.stop.prevent="favouriteRecipe(false)"></i>
    </div>
</template>

<script>
  export default {
    mounted() {
    },
    props:['selectedrecipe', 'loggedinuser'],
    data: function () {
        return {
            auth: this.loggedinuser,
            recipe: this.selectedrecipe,
            favourite: {
                recipe_id: ''
            },
            errors: null
        }
    },
    methods: {
        favouriteRecipe: function(type) {
            if (!this.auth) {
                var link = "/login";
                window.location.href = link;
            }

            this.favourite.recipe_id = this.recipe.id
            this.recipe.favourited = type
            axios
            .post('/recipe-favourite',
                this.favourite
            )
            .then(data => {
                
            })
            .catch(error => {
                this.errors = error.response.data.errors;
            });
        }
    }
  };
</script>