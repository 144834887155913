<template>
    <div>
   <!-- Receipe Post Search -->
        <div class="receipe-post-search mb-80">
            <div class="container">
                <form>
                    <div class="row">
                        <div class="col-12 col-lg-3">
                            <select name="food-type" id="food-type" class="selectBoxes" v-model="search.ft">
                                <option disabled value="0">Type</option>
                                <option v-for="foodType in foodTypes" :key="foodType.id" :value="foodType.id">
                                    {{foodType.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-lg-3">
                             <select name="cuisine" id="cuisine" class="selectBoxes" v-model="search.cu">
                                <option disabled value="0">Cuisine</option>
                                <option v-for="cuisine in cuisines" :key="cuisine.id" :value="cuisine.id">
                                    {{cuisine.name}}
                                </option>
                            </select>
                        </div>
                        <div class="col-12 col-lg-3">
                            <input type="search" name="search" v-model="search.q" placeholder="Search Recipes">
                        </div>

                        <div class="col-12 col-lg-3 text-right">
                            <button type="submit" class="btn delicious-btn" @click.stop.prevent="searchRecipes()">Search</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    mounted() {
        this.getFoodTypes();
        this.getCuisines();
    },
    data: function () {
        return {
            foodTypes: [],
            cuisines: [],
            search: {
                'q' : '',
                'ft' : 0,
                'cu' : 0,
            }
            
        }
    },
    methods: {
        searchRecipes:function() {
            var link = "/search?q="+this.search.q+"&ft="+this.search.ft+"&cu="+this.search.cu;
            window.location.href = link;
        },
        getFoodTypes: function() {
            var self = this;
            axios.get('/api/food-types')
                .then((response)=>{
                    self.foodTypes = response.data;
            })
        },
        getCuisines: function() {
            var self = this;
            axios.get('/api/cuisines')
                .then((response)=>{
                    self.cuisines = response.data;
            })
        }
    }
  };
</script>