<template>
    <div class="shopping-cart">
        <div class="cart-wrap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="main-heading">Shopping Cart</div>
                        <div class="table-cart">
                            <table v-if="$store.state.cartCount > 0">
                                <thead>
                                    <tr>
                                        <th>Recipe</th>
                                        <th>Quantity</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="recipe in $store.state.cart" :key="recipe.id">
                                        <td>
                                            <div class="display-flex align-center">
                                                <div class="img-product">
                                                    <img :src="'/storage/small-info-image/' +recipe.image" :alt="recipe.name" class="mCS_img_loaded">
                                                </div>
                                                <div class="name-product">
                                                   {{recipe.name}}
                                                </div>
                                                <!-- <div class="price">
                                                    £3.99
                                                </div> -->
                                            </div>
                                        </td>
                                        <td class="product-count">
                                            <form action="#" class="count-inlineflex">
                                                <div class="qtyminus" @click.stop.prevent="removeRecipeFromCart(recipe)">-</div>
                                                <input type="text" name="quantity" :value="recipe.quantity" class="qty">
                                                <div class="qtyplus" @click.stop.prevent="addRecipeToCart(recipe)">+</div>
                                            </form>
                                        </td>
                                        <td>
                                            <!-- <div class="total">
                                                $6,250.00
                                            </div> -->
                                        </td>
                                        <td>
                                            <a href="#" title="">
                                                <!-- <img src="images/icons/delete.png" alt="" class=""> -->
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-else>
                                No recipes in your cart
                            </div>
                            <!-- <div class="coupon-box">
                                <form action="#" method="get" accept-charset="utf-8">
                                    <div class="coupon-input">
                                        <input type="text" name="coupon code" placeholder="Coupon Code">
                                        <button type="submit" class="round-black-btn">Apply Coupon</button>
                                    </div>
                                </form>
                            </div> -->
                        </div>
                        <!-- /.table-cart -->
                    </div>
                    <!-- /.col-lg-8 -->
                    <div class="col-lg-4">
                        <div class="cart-totals">
                            <h3>Cart Totals</h3>
                            <form action="#" method="get" accept-charset="utf-8">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Recipes</td>
                                            <td class="subtotal">{{$store.state.cartCount}}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td>Shipping</td>
                                            <td class="free-shipping">Free Shipping</td>
                                        </tr> -->
                                        <tr class="total-row">
                                            <td>Ingredients</td>
                                            <td class="price-total">0</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="btn-cart-totals">
                                    <!-- <a href="#" class="update round-black-btn" title="">Update Cart</a> -->
                                    <a href="#" @click.stop.prevent="checkout()" class="btn delicious-btn" title="">Get Ingredient List</a>
                                </div>
                                <!-- /.btn-cart-totals -->
                            </form>
                            <!-- /form -->
                        </div>
                        <!-- /.cart-totals -->
                    </div>
                    <!-- /.col-lg-4 -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    mounted() {
    },
    data: function () {
        return {
            search: {
                'q' : ''
            }
            
        }
    },
    methods: {
        addRecipeToCart: function(recipe) {
            this.$store.commit('addToCart', recipe)   
        },
        removeRecipeFromCart: function(recipe) {
            this.$store.commit('removeFromCart', recipe)   
        },
        checkout: function(recipe) {
            this.$store.dispatch('checkoutCart').then((response) => {
                if (response.status && response.status == 401) {
                    var link = "/login";
                    window.location.href = link;
                } else {
                    this.$store.commit('clearCart')
                    var link = "/my-trolley/"+response.id;
                    window.location.href = link;
                }
            }).catch(error => {
                    
            });
        },
    }
  };
</script>