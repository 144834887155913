<template>
    <div>
        <!--START ADDING A INGREDIENT-->
        <div class="card-body" v-if="addIngredient">
            <div class="row">
                <label class="col-md-3 col-form-label">Ingredient</label>
                <div class="col-md-9">
                    <div class="form-group  pull-left" style="width:80%">
                        <vue-bootstrap-typeahead
                            v-model="query"
                            :data="ingredients"
                            :serializer="s => s.name"
                            @hit="selectIngredient($event)"
                            placeholder="Type an ingredient..."
                        />    
                    </div>
                    <div class="pull-left select-box-icons"> 
                        <a href="/admin/ingredients/create" target="__blank"><i class="nc-icon nc-simple-add"></i></a>
                    </div>
                    <div class="pull-left select-box-icons">
                        <i class="nc-icon nc-refresh-69" @click="getIngredients()"></i>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Extra Info</label>
                <div class="col-md-9">
                    <div class="form-group">
                        <input type="text" name="extra_info" class="form-control" placeholder="Extra Information" v-model="ingredient.extra_info">
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Quantity</label>
                <div class="col-md-9">
                    <div class="form-group">
                        <input type="text" name="quantity" class="form-control" placeholder="Quantity" v-model="ingredient.quantity">
                        {{ingredient.measurement_name}}
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Step No</label>
                <div class="col-md-9">
                    <div class="form-group pull-left" style="width:80%">
                        <input type="text" name="step_no" class="form-control" placeholder="Step Number" v-model="ingredient.step_no">
                    </div>
                    <div class="pull-left select-box-icons">
                        <i class="nc-icon nc-refresh-69" @click="getNextStepNo()"></i>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Ingredient Stage</label>
                <div class="col-md-9">
                    <div class="form-group pull-left" style="width:80%">
                       <select class="form-control" v-model="ingredient.ingredient_stage_id">
                            <option v-for="ingredientStage in ingredientStages" :key="ingredientStage.id" :value="ingredientStage.id">
                                {{ingredientStage.name}}
                            </option>
                        </select>
                    </div>
                    <div class="pull-left select-box-icons"> 
                        <a href="/admin/ingredient-stage/create" target="__blank"><i class="nc-icon nc-simple-add"></i></a>
                    </div>
                    <div class="pull-left select-box-icons">
                        <i class="nc-icon nc-refresh-69" @click="getIngredientStages()"></i>
                    </div>
                </div>
            </div>
        </div>
        <!--END ADD A  INGREDIENT-->

        <!--EDIT THE INGREDIENT-->
        <div class="card-body" v-if="editIngredient">
            <div class="row">
                <label class="col-md-3 col-form-label">Ingredient</label>
                <div class="col-md-9">
                    <div class="form-group  pull-left" style="width:80%">
                        <vue-bootstrap-typeahead
                            v-model="query"
                            :data="ingredients"
                            :serializer="s => s.name"
                            @hit="selectEditIngredient($event)"
                            placeholder="Type an ingredient..."
                        />    
                    </div>
                    <div class="pull-left select-box-icons"> 
                        <a href="/admin/ingredients/create" target="__blank"><i class="nc-icon nc-simple-add"></i></a>
                    </div>
                    <div class="pull-left select-box-icons">
                        <i class="nc-icon nc-refresh-69" @click="getIngredients()"></i>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Extra Info</label>
                <div class="col-md-9">
                    <div class="form-group">
                        <input type="text" name="extra_info" class="form-control" v-model="editIngredient.pivot.extra_info">
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Quantity</label>
                <div class="col-md-9">
                    <div class="form-group">
                        <input type="text" name="quantity" class="form-control" placeholder="Quantity" v-model="editIngredient.pivot.quantity">
                        {{editIngredient.measurement_name}}
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Step No</label>
                <div class="col-md-9">
                    <div class="form-group pull-left" style="width:80%">
                        <input type="text" name="step_no" class="form-control" placeholder="Step Number" v-model="editIngredient.pivot.step_no">
                    </div>
                    <div class="pull-left select-box-icons">
                        <i class="nc-icon nc-refresh-69" @click="getNextStepNo()"></i>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Ingredient Stage</label>
                <div class="col-md-9">
                    <div class="form-group pull-left" style="width:80%">
                       <select class="form-control" v-model="editIngredient.pivot.ingredient_stage_id">
                            <option v-for="ingredientStage in ingredientStages" :key="ingredientStage.id" :value="ingredientStage.id">
                                {{ingredientStage.name}}
                            </option>
                        </select>
                    </div>
                    <div class="pull-left select-box-icons"> 
                        <a href="/admin/ingredient-stage/create" target="__blank"><i class="nc-icon nc-simple-add"></i></a>
                    </div>
                    <div class="pull-left select-box-icons">
                        <i class="nc-icon nc-refresh-69" @click="getIngredientStages()"></i>
                    </div>
                </div>
            </div>
        </div>
        <!--END EDIT THE INGREDIENT-->

        <!--LIST ALL THE INGREDIENT-->
        <div class="card-body" v-if="!addIngredient && !editIngredient">
            <div class="table-responsive">
                <table class="table">
                    <thead class=" text-primary">
                        <th>
                            Name
                        </th>
                        <th>
                            Stage Name
                        </th>
                        <th>
                            Extra Info
                        </th>
                        <th>
                            Quantity
                        </th>
                        <th>
                            Step No
                        </th>
                        <th class="text-right">
                            Actions
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="(ingredient) in recipe.ingredients" :key="ingredient.pivot.id">
                            <td>
                                {{ingredient.name}}
                            </td>
                            <td>
                                {{ingredient.pivot.stage.name}}
                            </td>
                            <td>
                                {{ingredient.pivot.extra_info}}
                            </td>
                            <td>
                                {{ingredient.pivot.quantity}} {{ingredient.pivot.measurement.name}}
                            </td>
                            <td>
                                {{ingredient.pivot.step_no}}
                            </td>
                            <td class="text-right">
                                <a type="button" @click.stop.prevent="editIngredient = ingredient" href="">Edit</a>
                                &nbsp;|&nbsp;
                                <a type="button" class="text-danger" @click.stop.prevent="deleteIngredientForRecipe(ingredient)" href="">X</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--END LIST ALL THE INGREDIENT-->
        </div>
            
        <div class="card-footer">
            <div v-if="errors">
                <ul class="alert alert-danger">
                    <li v-for="(value, key, index) in errors" :key="index">{{ value }}</li>
                </ul>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    <button v-if="addIngredient" type="submit" class="btn btn-success btn-round" @click="saveIngredientToRecipe()">Save</button>
                    <button v-if="editIngredient" type="submit" class="btn btn-warning btn-round" @click="editIngredientForRecipe()">Edit</button>
                    
                    <hr>
                    <button v-if="editIngredient" type="submit" class="btn btn-warning btn-round" @click="editIngredient = ''">Hide Edit</button>
                    <button v-if="addIngredient" type="submit" class="btn btn-info btn-round" @click="addIngredient = !addIngredient">Show Ingredients</button>
                    <button v-if="!addIngredient" type="submit" class="btn btn-info btn-round" @click="addIngredient = !addIngredient">Add Ingredients</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    mounted() {
        this.getMeasurements();
        this.getIngredientStages();
        this.getIngredients();
    },
    props:['selectedrecipe'],
    data: function () {
        return {
            editIngredient: '',
            addIngredient: false,
            query: '',
            selectedIngredient: '',
            ingredients: [],
            recipe: this.selectedrecipe,
            ingredientStages: [],
            measurements: [],
            ingredient: {
                ingredient_id: '',
                ingredient_stage_id: 4,
                measurement_id: '',
                measurement_name: '',
                quantity: 0,
                step_no: 0
            },
            errors: null
        }
    },
    methods: {
        saveIngredientToRecipe: function() {
            axios
            .post('/admin/recipe-ingredient/'+this.recipe.slug, 
                this.ingredient
            )
            .then(data => {
                location.href = '/admin/recipe/' + this.recipe.slug + '/edit';
            })
            .catch(error => {
                this.errors = error.response.data.errors;
            });
        },
        editIngredientForRecipe: function() {
            var ingredienttoEdit = this.editIngredient.pivot;
            ingredienttoEdit.recipe_ingredient_id = this.editIngredient.pivot.id;

            console.log(ingredienttoEdit);
            axios
            .put('/admin/recipe-ingredient', 
                ingredienttoEdit
            )
            .then(data => {
                location.href = '/admin/recipe/' + this.recipe.slug + '/edit';
            })
            .catch(error => {
                this.errors = error.response.data.errors;
            });
        },
        deleteIngredientForRecipe: function(ingredient) {
            if(confirm("Do you really want to delete?")){
                axios
                .delete('/admin/recipe-ingredient/'+ingredient.pivot.id
                )
                .then(data => {
                    location.href = '/admin/recipe/' + this.recipe.slug + '/edit';
                })
                .catch(error => {
                    this.errors = error.response.data.errors;
                });
            }
        },
        selectIngredient: function(ingredient) {
            console.log(ingredient);
            this.ingredient.ingredient_id = ingredient.id
            this.ingredient.measurement_id = ingredient.measurement.id
            this.ingredient.measurement_name = ingredient.measurement.name
        },
        selectEditIngredient: function(ingredient) {
            console.log(ingredient);
            this.editIngredient.pivot.ingredient_id = ingredient.id
            this.editIngredient.pivot.measurement_id = ingredient.measurement.id
            this.editIngredient.measurement_name = ingredient.measurement.name
        },
        getNextStepNo: function() {
            this.ingredient.step_no = (this.recipe.ingredients.length+1);
        },
        getIngredients: function() {
            var self = this;
            axios.get('/api/ingredients')
                .then((response)=>{
                    self.ingredients = response.data;
            })
        },
        getMeasurements: function() {
            var self = this;
            axios.get('/api/measurements')
                .then((response)=>{
                    self.measurements = response.data;
            })
        },
        getIngredientStages: function() {
            var self = this;
            axios.get('/api/ingredient-stage')
                .then((response)=>{
                    self.ingredientStages = response.data;
            })
        }
    }
  };
</script>