<template>
    <div>
   <!-- Search Wrapper -->
        <div class="search-wrapper">
            <!-- Close Btn -->
            <div class="close-btn"><i class="fa fa-times" aria-hidden="true"></i></div>

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <form>
                            <input type="search" name="search" v-model="search.q" placeholder="Type any keywords...">
                            <button @click.stop.prevent="searchRecipes()" aria-label="Search Button"><i class="fa fa-search"  aria-hidden="true"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    mounted() {
    },
    data: function () {
        return {
            search: {
                'q' : ''
            }
            
        }
    },
    methods: {
        searchRecipes:function() {
            var link = "/search?q="+this.search.q;
            window.location.href = link;
        },
    }
  };
</script>