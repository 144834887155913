let cart = window.localStorage.getItem('cart');
let cartCount = window.localStorage.getItem('cartCount');

export default {
    
	state: {
        cart: cart ? JSON.parse(cart) : [],
        cartCount: cartCount ? parseInt(cartCount) : 0,
    },
    mutations: {
        addToCart (state, recipe) {
            let found = state.cart.find(item => item.id == recipe.id);

            if (found) {
                found.quantity ++;
            } else {
                state.cart.push(recipe);
        
                Vue.set(recipe, 'quantity', 1);
            }
        
            state.cartCount++;

            this.commit('saveCart');
        },
        removeFromCart (state, recipe) {
            let found = state.cart.find(item => item.id == recipe.id);

            if (found.quantity > 1) {
                found.quantity --;
            } else {
                let index = state.cart.indexOf(recipe);
        
                if (index > -1) {
                    let product = state.cart[index];
                    state.cart.splice(index, 1);
                }
            }
        
            state.cartCount--;

            this.commit('saveCart');
        },
        deleteFromCart(state, recipe) {
            let index = state.cart.indexOf(recipe);
        
            if (index > -1) {
                let product = state.cart[index];
                state.cartCount -= product.quantity;
        
                state.cart.splice(index, 1);
            }

            this.commit('saveCart');
        },
        clearCart(state, recipe) {
            state.cartCount = 0;
            state.cart=[];

            this.commit('saveCart');
        },
        saveCart(state) {
            window.localStorage.setItem('cart', JSON.stringify(state.cart));
            window.localStorage.setItem('cartCount', state.cartCount);
        },
        checkout(state) {
            
        }
    },
    getters: {
        getCart(state){
           return state.cart
        }
    },
    actions: {
        checkoutCart({ getters }) {
            let cart = getters.getCart;
            
            var shoppingList = [];
            $.each( cart, function( key, value ) {
                shoppingList.push(
                    {
                        'recipe_id' : value.id,
                        'quantity' : value.quantity
                    }
                )
            });

            return axios.post('/shopping-cart', 
                { 
                    cart: shoppingList 
                }
            ).then((response) => {
                return response.data
            }).catch(error => {
                if (error.response) {
                    return error.response;
                    
                  }
            });;
        }
    }
}