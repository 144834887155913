<template>
    <li>
        <a href="/shopping-cart"><i class="fas fa-shopping-cart"></i> <span v-if="$store.state.cartCount > 0">({{ $store.state.cartCount }})</span></a>                                               
    </li>
</template>

<script>
  export default {
    mounted() {
    },
    data: function () {
        return {
            
        }
    },
    methods: {
    }
  };
</script>