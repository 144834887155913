<template>
    <div>
        <div v-if="!rated">
            <div class="popup rating-text" @click="showPopupRating()">Rate this recipe!
                <span class="popuptext show" v-if="auth && showPopup">
                    <h6 class="">Rate this recipe</h6>
                    <div class="py-2">
                        <i v-if="selectedRating >= 1" @mouseover="selectedRating = 1" class="fas fa-star" aria-hidden="true"></i>
                        <i v-if="selectedRating >= 2" @mouseover="selectedRating = 2" class="fas fa-star" aria-hidden="true"></i>
                        <i v-if="selectedRating >= 3" @mouseover="selectedRating = 3" class="fas fa-star" aria-hidden="true"></i>
                        <i v-if="selectedRating >= 4" @mouseover="selectedRating = 4" class="fas fa-star" aria-hidden="true"></i>
                        <i v-if="selectedRating == 5" @mouseover="selectedRating = 5" class="fas fa-star" aria-hidden="true"></i>
                        <i v-if="selectedRating == 1" @mouseover="selectedRating = 2"  class="far fa-star" aria-hidden="true"></i>
                        <i v-if="selectedRating <= 2" @mouseover="selectedRating = 3" class="far fa-star" aria-hidden="true"></i>
                        <i v-if="selectedRating <= 3" @mouseover="selectedRating = 4" class="far fa-star" aria-hidden="true"></i>
                        <i v-if="selectedRating < 5"  @mouseover="selectedRating = 5" class="far fa-star" aria-hidden="true"></i>
                    </div>
                    <a href="#" @click.stop.prevent="rateRecipe()" class="btn delicious-btn btn-5">Rate</a>
                    <a href="#" @click.stop.prevent="showPopup = false" class="cancelLink">Cancel</a>
                </span>
                <span class="popuptext show" v-if="!auth && showPopup">
                    <a href="/login" class="links">Sign In</a> or <a href="/register" class="links">register</a> to rate this recipe.
                    <a href="#" @click.stop.prevent="showPopup = false" class="cancelLink">Cancel</a>
                </span>
            </div>
        </div>
            
        <div v-else>
            <span class="rating-text">Thank you for rating!</span>
        </div>

        
    </div>
</template>

<script>
  export default {
    mounted() {
    },
    props:['selectedrecipe', 'loggedinuser', 'hasrated'],
    data: function () {
        return {
            showPopup: false,
            selectedRating: 1,
            rated: this.hasrated,
            auth: this.loggedinuser,
            recipe: this.selectedrecipe,
            rating: {
                recipe_id: '',
                value: 5
            },
            errors: null
        }
    },
    methods: {
        rateRecipe: function() {
            this.rating.recipe_id = this.recipe.id
            this.rating.value = this.selectedRating
            axios
            .post('/recipe-rating',
                this.rating
            )
            .then(data => {
                this.rated = true;
                this.showPopup = false;
            })
            .catch(error => {
                this.errors = error.response.data.errors;
            });
        },
        showPopupRating()
        {
            this.showPopup = true;
        }
    }
  };
</script>