<template>
    <div>
        <a href="" @click.stop.prevent="addRecipeToCart()" class="btn delicious-btn">Add to <i class="fas fa-shopping-cart"></i></a>
        
    </div>
</template>

<script>
  export default {
    mounted() {
    },
    props:['selectedrecipe', 'loggedinuser'],
    data: function () {
        return {
            auth: this.loggedinuser,
            recipe: this.selectedrecipe,
        }
    },
    methods: {
        addRecipeToCart: function(type) {
            this.$store.commit('addToCart', this.recipe)
            let instance = Vue.$toast.open({
                message: 'Added to your cart!',
                position: 'bottom',
                // all of other options may go here
            });
        }
    }
  };
</script>