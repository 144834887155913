<template>
    <div class="pt-4">
        <div class="row">
            <div class="col-12">
                <div class="section-heading text-left">
                    <h3>Leave a comment</h3>
                </div>
            </div>
        </div>

        <div class="row" v-if="auth">
            <div class="col-12" v-if="!commentSent">
                <div class="contact-form-area">
                    <form action="#" method="post">
                        <div class="row">
                            <div class="col-12">
                                <textarea v-model="comment.comment" name="message" class="form-control" id="message" cols="30" rows="10" placeholder="Comment"></textarea>
                            </div>
                            <div class="col-12">
                                <button class="btn delicious-btn mt-30" @click.stop.prevent="commentOnRecipe" type="submit">Post Comment</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-12" v-if="commentSent">
                <div class="contact-form-area">
                    Thank you. Your comment will be approved shortly.
                </div>
            </div>
        </div>
        <div class="row" v-if="!auth">
            <div class="col-12">
                <div class="contact-form-area">
                    <a href="/login" class="commentregisterlogintext">Login</a> or <a href="/register" class="commentregisterlogintext">register</a> to post a comment
                </div>
            </div>
        </div>
        <div class="container pt-4 contact-form-area">
            
            <div class="card" style="border:none">
                <div class="card-body">
                    <div class="row mb-5" v-for="comment in recipe.comments" :key="comment.id">
                        <div class="col-md-2 text-center">
                            <img src="/paper/img/profile-small.jpeg" class="img rounded-circle img-fluid comment-image"/>
                            <p class="text-secondary text-center comment-created-at">{{moment(comment.created_at).fromNow()}}</p>
                        </div>
                        <div class="col-md-10">
                            <p>
                                <a class="float-left" href="#"><strong>{{comment.user.name}}</strong></a>
                                <span class="float-right"><i class="text-warning fa fa-star"></i></span>
                                <span class="float-right"><i class="text-warning fa fa-star"></i></span>
                                <span class="float-right"><i class="text-warning fa fa-star"></i></span>
                                <span class="float-right"><i class="text-warning fa fa-star"></i></span>
                                <span class="float-right"><i class="text-warning fa fa-star"></i></span>

                        </p>
                        <div class="clearfix"></div>
                            <p>{{comment.comment}}</p>
                            <p>
                                <!-- <a class="float-right btn btn-outline-primary ml-2"> <i class="fa fa-reply"></i> Reply</a>
                                <a class="float-right btn text-white btn-danger"> <i class="fa fa-heart"></i> Like</a> -->
                            </p>
                        </div>
                    </div>
                    <!-- <div class="card card-inner">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-2">
                                    <img src="https://image.ibb.co/jw55Ex/def_face.jpg" class="img img-rounded img-fluid"/>
                                    <p class="text-secondary text-center">15 Minutes Ago</p>
                                </div>
                                <div class="col-md-10">
                                    <p><a href="https://maniruzzaman-akash.blogspot.com/p/contact.html"><strong>Maniruzzaman Akash</strong></a></p>
                                    <p>Lorem Ipsum is simply dummy text of the pr make  but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    <p>
                                        <a class="float-right btn btn-outline-primary ml-2">  <i class="fa fa-reply"></i> Reply</a>
                                        <a class="float-right btn text-white btn-danger"> <i class="fa fa-heart"></i> Like</a>
                                </p>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    var moment = require('moment');
  export default {
    mounted() {
    },
    props:['selectedrecipe', 'loggedinuser'],
    data: function () {
        return {
            commentSent: false,
            auth: this.loggedinuser,
            recipe: this.selectedrecipe,
            comment: {
                recipe_id: '',
                comment: ''
            },
            errors: null,
            moment: moment
        }
    },
    methods: {
        commentOnRecipe: function() {
            this.comment.recipe_id = this.recipe.id
            this.commentSent = false;
            axios
            .post('/recipe-comment',
                this.comment
            )
            .then(data => {
                this.comment.comment = '';
                this.commentSent = true;
            })
            .catch(error => {
                this.errors = error.response.data.errors;
            });
        }
    }
  };
</script>