<template>
    <div>
        <div class="card-body">
            <div class="row">
                <label class="col-md-3 col-form-label">Name <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group">
                        <input type="text" name="name" class="form-control" placeholder="Ingredient Name" v-model="ingredient.name" required>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Default Measurement <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group pull-left" style="width:80%">
                       <select class="form-control" v-model="ingredient.measurement_id">
                            <option disabled value="">Please select one</option>
                            <option v-for="measurement in measurements" :key="measurement.id" :value="measurement.id">
                                {{measurement.name}}
                            </option>
                        </select>
                    </div>
                    <div class="pull-left select-box-icons"> 
                        <a href="/admin/measurements/create" target="__blank"><i class="nc-icon nc-simple-add"></i></a>
                    </div>
                    <div class="pull-left select-box-icons">
                        <i class="nc-icon nc-refresh-69" @click="getMeasurements()"></i>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Supermarket Section <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group">
                       <select class="form-control" v-model="ingredient.supermarket_section_id">
                            <option disabled value="">Please select one</option>
                            <option v-for="supermarketSection in supermarketSections" :key="supermarketSection.id" :value="supermarketSection.id">
                                {{supermarketSection.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div v-if="errors">
                <ul class="alert alert-danger">
                    <li v-for="(value, key, index) in errors" :key="index">{{ value }}</li>
                </ul>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    <button v-if="!editingAIngredient" type="submit" class="btn btn-info btn-round" @click="saveIngredient()">Save</button>
                    <button v-if="editingAIngredient" type="submit" class="btn btn-warning btn-round" @click="editIngredient()">Edit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    mounted() {
        this.getMeasurements();
        this.getSuperMarketSections();

        if (typeof this.ingredienttoedit !== 'undefined') {
            this.editingAIngredient = true;
            this.ingredient = this.ingredienttoedit;
        }

    },
    props:['ingredienttoedit'],
    data: function () {
        return {
            editingAIngredient: false,
            supermarketSections: [],
            measurements: [],
            ingredient: {
                name: '',
                supermarket_section_id: '',
                measurement_id: ''
            },
            errors: null
        }
    },
    methods: {
        saveIngredient: function() {
            axios
            .post('/admin/ingredients', 
                this.ingredient
            )
            .then(data => {
                location.href = '/admin/ingredients';
            })
            .catch(error => {
                this.errors = error.response.data.errors;
            });
        },
        editIngredient: function() {
            axios
            .put('/admin/ingredients/' + this.ingredient.id, 
                this.ingredient 
            )
            .then(data => {
                location.href = '/admin/ingredients/' + this.ingredient.id + '/edit';
            })
            .catch(error => {
                this.errors = error.response.data.errors;
            });
        },
        getMeasurements: function() {
            var self = this;
            axios.get('/api/measurements')
                .then((response)=>{
                    self.measurements = response.data;
            })
        },
        getSuperMarketSections: function() {
            var self = this;
            axios.get('/api/supermarket-sections')
                .then((response)=>{
                    self.supermarketSections = response.data;
            })
        }
    }
  };
</script>