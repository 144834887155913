<template>
    <div>
        <!--START ADDING A Method-->
        <div class="card-body" v-if="addMethod">
            <div class="row">
                <label class="col-md-3 col-form-label">Description <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group">
                        <textarea rows="5" name="description" class="form-control" v-model="method.description" placeholder="Describe the method" required></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Step No</label>
                <div class="col-md-9">
                    <div class="form-group pull-left" style="width:80%">
                        <input type="text" name="step_no" class="form-control" placeholder="Step Number" v-model="method.step_no">
                    </div>
                    <div class="pull-left select-box-icons">
                        <i class="nc-icon nc-refresh-69" @click="getNextStepNo()"></i>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Method Stage</label>
                <div class="col-md-9">
                    <div class="form-group pull-left" style="width:80%">
                       <select class="form-control" v-model="method.method_stage_id">
                            <option v-for="methodStage in methodStages" :key="methodStage.id" :value="methodStage.id">
                                {{methodStage.name}}
                            </option>
                        </select>
                    </div>
                    <div class="pull-left select-box-icons"> 
                        <a href="/admin/method-stage/create" target="__blank"><i class="nc-icon nc-simple-add"></i></a>
                    </div>
                    <div class="pull-left select-box-icons">
                        <i class="nc-icon nc-refresh-69" @click="getMethodStages()"></i>
                    </div>
                </div>
            </div>
        </div>
        <!--END ADD A  Method-->

        <!--EDIT THE Method-->
        <div class="card-body" v-if="editMethod">
            <div class="row">
                <label class="col-md-3 col-form-label">Description <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group">
                        <textarea rows="5" name="description" class="form-control" v-model="editMethod.description" placeholder="Describe the method" required></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Step No</label>
                <div class="col-md-9">
                    <div class="form-group pull-left" style="width:80%">
                        <input type="text" name="step_no" class="form-control" placeholder="Step Number" v-model="editMethod.step_no">
                    </div>
                    <div class="pull-left select-box-icons">
                        <i class="nc-icon nc-refresh-69" @click="getNextStepNo()"></i>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Method Stage</label>
                <div class="col-md-9">
                    <div class="form-group pull-left" style="width:80%">
                       <select class="form-control" v-model="editMethod.method_stage_id">
                            <option v-for="methodStage in methodStages" :key="methodStage.id" :value="methodStage.id">
                                {{methodStage.name}}
                            </option>
                        </select>
                    </div>
                    <div class="pull-left select-box-icons"> 
                        <a href="/admin/method-stage/create" target="__blank"><i class="nc-icon nc-simple-add"></i></a>
                    </div>
                    <div class="pull-left select-box-icons">
                        <i class="nc-icon nc-refresh-69" @click="getMethodStages()"></i>
                    </div>
                </div>
            </div>
        </div>
        <!--END EDIT THE METHOD-->

        <!--LIST ALL THE METHOD-->
        <div class="card-body" v-if="!addMethod && !editMethod">
            <div class="table-responsive">
                <table class="table">
                    <thead class=" text-primary">
                        <th>
                            Description
                        </th>
                        <th>
                            Stage Name
                        </th>
                        <th>
                            Step No
                        </th>
                        <th class="text-right">
                            Actions
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="(method) in recipe.methods" :key="method.id">
                            <td>
                                {{method.description}}
                            </td>
                            <td>
                                {{method.stage.name}}
                            </td>
                            <td>
                                {{method.step_no}}
                            </td>
                            <td class="text-right">
                                <a type="button" @click.stop.prevent="editMethod = method" href="">Edit</a>
                                &nbsp;|&nbsp;
                                <a type="button" class="text-danger" @click.stop.prevent="deleteMethodForRecipe(method)" href="">X</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!--END LIST ALL THE METHOD-->
        </div>
            
        <div class="card-footer">
            <div v-if="errors">
                <ul class="alert alert-danger">
                    <li v-for="(value, key, index) in errors" :key="index">{{ value }}</li>
                </ul>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    <button v-if="addMethod" type="submit" class="btn btn-success btn-round" @click="saveMethodToRecipe()">Save</button>
                    <button v-if="editMethod" type="submit" class="btn btn-warning btn-round" @click="editMethodForRecipe()">Edit</button>
                    
                    <hr>
                    <button v-if="editMethod" type="submit" class="btn btn-warning btn-round" @click="editMethod = ''">Hide Edit</button>
                    <button v-if="addMethod" type="submit" class="btn btn-info btn-round" @click="addMethod = !addMethod">Show Methods</button>
                    <button v-if="!addMethod" type="submit" class="btn btn-info btn-round" @click="addMethod = !addMethod">Add Method</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    mounted() {
        this.getMethodStages();
    },
    props:['selectedrecipe'],
    data: function () {
        return {
            editMethod: '',
            addMethod: false,
            recipe: this.selectedrecipe,
            methodStages: [],
            method: {
                recipe_id: '',
                step_no: '',
                description: '',
                method_stage_id: 1
            },
            errors: null
        }
    },
    methods: {
        saveMethodToRecipe: function() {
            this.method.recipe_id = this.recipe.id
            axios
            .post('/admin/recipe-method',
                this.method
            )
            .then(data => {
                location.href = '/admin/recipe/' + this.recipe.slug + '/edit';
            })
            .catch(error => {
                this.errors = error.response.data.errors;
            });
        },
        editMethodForRecipe: function() {
            axios
            .put('/admin/recipe-method/' + this.editMethod.id, 
                this.editMethod
            )
            .then(data => {
                location.href = '/admin/recipe/' + this.recipe.slug + '/edit';
            })
            .catch(error => {
                this.errors = error.response.data.errors;
            });
        },
        deleteMethodForRecipe: function(method) {
            if(confirm("Do you really want to delete?")){
                axios
                .delete('/admin/recipe-method/' + method.id
                )
                .then(data => {
                    location.href = '/admin/recipe/' + this.recipe.slug + '/edit';
                })
                .catch(error => {
                    this.errors = error.response.data.errors;
                });
            }
        },
        getNextStepNo: function() {
            this.method.step_no = (this.recipe.methods.length+1);
        },
        getMethodStages: function() {
            var self = this;
            axios.get('/api/method-stage')
                .then((response)=>{
                    self.methodStages = response.data;
            })
        }
    }
  };
</script>