<template>
    <div>
        <div class="card-body">
            <div class="row">
                <label class="col-md-3 col-form-label">Image <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group pull-left">
                        <label for="description" class="image-upload">Upload Image</label>
                        <input type="file" name="image" class="form-control-file" id="image" @change="onFileChange">
                        <img v-bind:src="imagePreview" width="100" height="100" v-show="showPreview"/> 
                    </div>
                </div>
            </div>
            
            <div class="row">
                <label class="col-md-3 col-form-label">Name <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group">
                        <input type="text" name="name" class="form-control" placeholder="Recipe Name" v-model="recipe.name" required>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Description <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group">
                        <textarea rows="5" name="description" class="form-control" v-model="recipe.description" placeholder="Describe the recipe" required></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Snippet <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group">
                        <textarea name="snippet" class="form-control" v-model="recipe.snippet" placeholder="Small snippet about the recipe" required></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Keywords (Comma Sep) <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group">
                        <input type="text" name="keywords" class="form-control" placeholder="winter apple pie, nutmeg crust, cake for a party, coffee" v-model="recipe.keywords" required>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Serves <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group">
                       <select class="form-control" v-model="recipe.serves">
                            <option disabled value="">Please select one</option>
                            <option v-for="index in 50" :key="index">{{index}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Prep Time (mins) <sup>*</sup></label>
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" name="prep_time" class="form-control" placeholder="Prep Time" v-model="recipe.prep_time" required>
                    </div>
                </div>
                <label class="col-md-3 col-form-label">Cook Time (mins)</label>
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" name="cook_time" class="form-control" placeholder="Cooking Time" v-model="recipe.cook_time" required>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Meal Type <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group pull-left" style="width:80%">
                       <select class="form-control" v-model="recipe.meal_type_id">
                            <option disabled value="">Please select one</option>
                            <option v-for="mealType in mealTypes" :key="mealType.id" :value="mealType.id">
                                {{mealType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="pull-left select-box-icons"> 
                        <a href="/admin/meal-types/create" target="__blank"><i class="nc-icon nc-simple-add"></i></a>
                    </div>
                    <div class="pull-left select-box-icons">
                        <i class="nc-icon nc-refresh-69" @click="getMealType()"></i>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Cuisine <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group pull-left" style="width:80%">
                       <select class="form-control" v-model="recipe.cuisine_id">
                            <option disabled value="">Please select one</option>
                            <option v-for="cuisine in cuisines" :key="cuisine.id" :value="cuisine.id">
                                {{cuisine.name}}
                            </option>
                        </select>
                    </div>
                    <div class="pull-left select-box-icons"> 
                        <a href="/admin/cuisines/create" target="__blank"><i class="nc-icon nc-simple-add"></i></a>
                    </div>
                    <div class="pull-left select-box-icons">
                        <i class="nc-icon nc-refresh-69" @click="getCuisines()"></i>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Food Type <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group pull-left" style="width:80%">
                       <select class="form-control" v-model="recipe.food_type_id">
                            <option disabled value="">Please select one</option>
                            <option v-for="foodType in foodTypes" :key="foodType.id" :value="foodType.id">
                                {{foodType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="pull-left select-box-icons"> 
                        <a href="/admin/food-types/create" target="__blank"><i class="nc-icon nc-simple-add"></i></a>
                    </div>
                    <div class="pull-left select-box-icons">
                        <i class="nc-icon nc-refresh-69" @click="getFoodTypes()"></i>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Difficulty <sup>*</sup></label>
                <div class="col-md-9">
                    <div class="form-group">
                       <select class="form-control" v-model="recipe.difficulty">
                            <option disabled value="0">Please select one</option>
                            <option value="1">Easy</option>
                            <option value="2">Medium</option>
                            <option value="3">Hard</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Calories</label>
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" name="kcal" class="form-control" placeholder="Calories" v-model="recipe.kcal" required>
                    </div>
                </div>
                <label class="col-md-3 col-form-label">Fat</label>
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" name="fat" class="form-control" placeholder="Fat" v-model="recipe.fat" required>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Saturates</label>
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" name="saturates" class="form-control" placeholder="Saturates" v-model="recipe.saturates" required>
                    </div>
                </div>
                <label class="col-md-3 col-form-label">Carbs</label>
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" name="carbs" class="form-control" placeholder="Carbs" v-model="recipe.carbs" required>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Sugars</label>
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" name="sugars" class="form-control" placeholder="Sugars" v-model="recipe.sugars" required>
                    </div>
                </div>
                <label class="col-md-3 col-form-label">Fibre</label>
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" name="fibre" class="form-control" placeholder="Fibre" v-model="recipe.fibre" required>
                    </div>
                </div>
            </div>
            <div class="row">
                <label class="col-md-3 col-form-label">Protein</label>
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" name="protein" class="form-control" placeholder="Protein" v-model="recipe.protein" required>
                    </div>
                </div>
                <label class="col-md-3 col-form-label">Salt</label>
                <div class="col-md-3">
                    <div class="form-group">
                        <input type="text" name="salt" class="form-control" placeholder="Salt" v-model="recipe.salt" required>
                    </div>
                </div>
            </div>
            
            <!-- <div class="row">
                <label class="col-md-3 col-form-label">Meal Type</label>
                <div class="col-md-9">
                    <div class="form-group">
                        <vue-bootstrap-typeahead
                        v-model="recipe.meal_type_id"
                        :data="mealTypes"
                        @hit="selectMealType"
                        />    
                    </div>
                    
                    <span class="invalid-feedback" style="display: block;" role="alert" v-if="errors.name.length > 0">
                        <strong>{{ errors.name }}</strong>
                    </span>
                </div>
            </div> -->
        </div>
        <div class="card-footer">
            <div v-if="errors">
                <ul class="alert alert-danger">
                    <li v-for="(value, key, index) in errors" :key="index">{{ value }}</li>
                </ul>
            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    <button :disabled="saving" v-if="!editingARecipe" type="submit" class="btn btn-info btn-round" @click="saveRecipe()">Save</button>
                    <button :disabled="saving" v-if="editingARecipe" type="submit" class="btn btn-warning btn-round" @click="editRecipe()">Edit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    mounted() {
        this.getMealType();
        this.getCuisines();
        this.getFoodTypes();

        if (typeof this.recipetoedit !== 'undefined') {
            this.editingARecipe = true;
            this.recipe = this.recipetoedit;
        }

    },
    props:['recipetoedit'],
    data: function () {
        return {
            saving: false,
            imageEdited: false,
            editingARecipe: false,
            imagePreview: null,
            showPreview: false,
            mealTypes: [],
            cuisines: [],
            foodTypes: [],
            recipe: {
                name: '',
                description: '',
                snippet: '',
                image: '',
                serves: '',
                prep_time: 0,
                cook_time: 0,
                meal_type_id: '',
                cuisine_id: '',
                food_type_id: '',
                kcal: 0,
                fat:0,
                saturates: 0,
                carbs:0,
                sugars: 0,
                fibre:0,
                protein: 0,
                salt:0,
                difficulty: 0
            },
            errors: null
        }
    },
    methods: {
        saveRecipe: function() {
            this.saving = true;
            /*
                Initialize the form data
            */
            let formData = new FormData();

            /*
                Add the form data we need to submit
            */
            $.each(this.recipe, function( index, value ) {
                formData.append(index, value);
            });
            

            axios
            .post('/admin/recipe', 
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            .then(data => {
                location.href = '/admin/recipe';
            })
            .catch(error => {
                this.saving = false;
                this.errors = error.response.data.errors;
            });
        },
        editRecipe: function() {
            this.saving = true;
            var self = this;
            /*
                Initialize the form data
            */
            let formData = new FormData();

            /*
                Add the form data we need to submit
            */
            $.each(this.recipe, function( index, value ) {
                if (!self.imageEdited && index =='image') {

                } else {
                    console.log(index + ' : ' + value);
                    formData.append(index, value);
                }
                
            });
            
            formData.append("_method", "put");
            
            axios
            .post('/admin/recipe/' + this.recipe.slug, 
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            .then(data => {
                location.href = '/admin/recipe/' + this.recipe.slug + '/edit';
            })
            .catch(error => {
                this.saving = false;
                this.errors = error.response.data.errors;
            });
        },
        selectMealType: function() {
            this.recipe.meal_type_id = this.query.id
        },
        getMealType: function() {
            var self = this;
            axios.get('/api/meal-types')
                .then((response)=>{
                    self.mealTypes = response.data;
            })
        },
        getCuisines: function() {
            var self = this;
            axios.get('/api/cuisines')
                .then((response)=>{
                    self.cuisines = response.data;
            })
        },
        getFoodTypes: function() {
            var self = this;
            axios.get('/api/food-types')
                .then((response)=>{
                    self.foodTypes = response.data;
            })
        },
        onFileChange(event){
             /*
            Set the local file variable to what the user has selected.
            */
            this.recipe.image = event.target.files[0];

            /*
            Initialize a File Reader object
            */
            let reader  = new FileReader();

            /*
            Add an event listener to the reader that when the file
            has been loaded, we flag the show preview as true and set the
            image to be what was read from the reader.
            */
            reader.addEventListener("load", function () {
                this.showPreview = true;
                this.imagePreview = reader.result;
            }.bind(this), false);

            /*
            Check to see if the file is not empty.
            */
            if( this.recipe.image ){
                this.imageEdited = true;
                /*
                    Ensure the file is an image file.
                */
                if ( /\.(jpe?g|png|gif)$/i.test( this.recipe.image.name ) ) {
                    /*
                    Fire the readAsDataURL method which will read the file in and
                    upon completion fire a 'load' event which we will listen to and
                    display the image in the preview.
                    */
                    reader.readAsDataURL( this.recipe.image );
                }
            }
        }
    }
  };
</script>