require('./bootstrap');

Vue.component('recipe-component', require('./components/RecipeComponent.vue').default);
Vue.component('ingredient-component', require('./components/IngredientComponent.vue').default);
Vue.component('recipe-ingredients-component', require('./components/RecipeIngredientsComponent.vue').default);
Vue.component('recipe-method-component', require('./components/RecipeMethodComponent.vue').default);
Vue.component('recipe-rating-component', require('./components/RecipeRatingComponent.vue').default);
Vue.component('recipe-comment-component', require('./components/RecipeCommentComponent.vue').default);
Vue.component('recipe-index', require('./components/RecipeIndexComponent.vue').default);
Vue.component('recipe-category-search', require('./components/RecipeWithCategorySearch.vue').default);
Vue.component('recipe-bookmark-component', require('./components/RecipeBookMarkComponent.vue').default);
Vue.component('recipe-favourite-component', require('./components/RecipeFavouriteComponent.vue').default);
Vue.component('search-bar', require('./components/SearchBarComponent.vue').default);
Vue.component('shopping-cart', require('./components/ShoppingCartComponent.vue').default);
Vue.component('add-recipe-to-shopping-cart', require('./components/AddToCartButtonComponent.vue').default);
Vue.component('show-shopping-cart-list', require('./components/CartListComponent.vue').default);
Vue.component('ingredients-shopping-list', require('./components/IngredientsShoppingListComponent.vue').default);


import Vuex from 'vuex'
Vue.use(Vuex)

import storeData from "./store/index"

const store = new Vuex.Store(
   storeData
)

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

const app = new Vue({
   el: '#app',
   store: store,
});